import { motion } from "framer-motion";
import profile_pic from "../../assets/profile_pic.png";

function MainSection() {
  const variants = {
    hidden: {
      opacity: 0,
      y: 100,
    },

    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
      },
    },

    hoverEffect: {
      scale: 1.05,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <section className="flex h-screen items-center justify-center">
      <div className="flex h-2/3 w-1/2 flex-col justify-center rounded-3xl bg-white shadow-md">
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          whileHover="hoverEffect"
        >
          <div className="m-auto mb-5 w-80 overflow-hidden rounded-full border-4 border-solid border-gray-400 bg-gray-500 shadow-lg md:w-96">
            <img className="inline" src={profile_pic} alt="Gavin Le"></img>
          </div>
        </motion.div>
        <div className="text-center text-5xl">
          <span className="type-greeting"></span>
          <br />
          <span className="type-first"></span>
          <span className="font-bold text-teal-500 type-info"></span>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
