import './css/App.css';
import { MainSection, Experiences } from './components/sections';

function App() {

  return (
    <div className='bg-slate-50'>
      <MainSection />
      <Experiences />
    </div>
  );
}

export default App;
