import { motion } from "framer-motion";

function Experiences() {
  const skills = [
    { id: 1, title: "VB.NET", level: 100 / 100, color: "bg-green-500" },
    { id: 2, title: "HTML", level: 95 / 100, color: "bg-teal-400" },
    { id: 3, title: "CSS", level: 80 / 100, color: "bg-blue-400" },
    { id: 4, title: "Javascript", level: 75 / 100, color: "bg-orange-400" },
    { id: 5, title: "C|C++", level: 70 / 100, color: "bg-slate-500" },
  ];

  const variants = {
    hidden: {
      originX: 0,
      scaleX: 0,
    },

    show25: {
      scaleX: 0.25,
    },

    show50: {
      scaleX: 0.5,
    },

    show75: {
      scaleX: 0.75,
    },

    show100: {
      scaleX: 1,
    },
  };

  return (
    <section className="flex h-screen flex-col items-center justify-center">
      <div className="h-4/7 w-3/5 rounded-3xl bg-white p-5 shadow-xl">
        <h2 className="text-center">My Skills</h2>
        <div className="flex h-full flex-col px-5 mt-10">
          {skills.map((item) => (
            <div key={item.id} className="flex w-full items-center mb-5">
              <motion.span
                className="w-28"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                {item.title}
              </motion.span>
              <motion.div
                className={"m-1 h-5 w-full rounded-3xl " + item.color}
                variants={variants}
                initial="hidden"
                whileInView={{ scaleX: item.level }}
                transition={{ duration: 1 }}
              ></motion.div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Experiences;
